<template>
  <SplNuxtLink
    is-external
    :to="url"
    :is-disabled="linkDisabled || hasLocationWithNoLanding"
  >
    <div class="search-result">
      <div class="search-result__icon">
        <MapMarkerIcon
          v-if="location.type == 'community'"
          variant="dark"
          :size="20"
        />
        <LocationIcon
          v-else
          variant="dark"
          :size="20"
        />
      </div>
      <div class="search-result__labels">
        <p class="search-result__labels__header">
          {{ location.label }}, {{ readableType }}
        </p>
        <p>
          <span v-if="shouldShowLocationParent">
            {{ parentLabel }},
          </span>
          <span>{{ countryLabel }}</span>
        </p>
      </div>
    </div>
  </SplNuxtLink>
</template>

<script lang="ts">
import SplNuxtLink from '../../shared/SplNuxtLink.vue'

import LocationIcon from '../../shared/icons/Location.icon.vue'
import MapMarkerIcon from '../../shared/icons/MapMarker.icon.vue'

import { LocationMinified } from '~/core/ts/entity/Location'

import { joinPaths } from '~/core/ts/util/string'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    LocationIcon,
    MapMarkerIcon,
  },
  props: {
    location: {
      type: Object as PropType<LocationMinified>,
      required: true
    },
    locationParent: {
      type: Object as PropType<LocationMinified | null>,
      required: false,
    },
    locationCountry: {
      type: Object as PropType<LocationMinified | null>,
      required: false,
    },
    linkDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const runtime = useRuntimeConfig()

    const url = computed(() => {
      if (props.location.type !== 'region' && props.locationParent && props.locationParent.type !== 'country') {
        return joinPaths(
          runtime.public.urls.spasBaseUrl,
          joinPaths(props.locationParent.slug, props.location.slug)
        )
      }

      return joinPaths(runtime.public.urls.spasBaseUrl, props.location.slug)
    })

    const types: Record<string, string> = {
      country: 'País',
      community: 'comunidad',
      region: 'provincia',
      city: 'ciudad'
    }

    const readableType = computed(() => types[props.location.type] || props.location.type)

    const hasLocationWithNoLanding = computed(() => !props.location.hasAValidLanding)

    const shouldShowLocationParent = computed(() => {
      if (!props.locationParent) {
        return false
      }

      if (props.locationParent.type === 'country') {
        return false
      }

      return true
    })

    const parentLabel = computed(() => {
      if (!props.locationParent) {
        return ''
      }

      return props.locationParent.label
    })

    const countryLabel = computed(() => {
      if (!props.locationCountry) {
        return 'España'
      }

      if (props.locationCountry.label === 'Spain') {
        return 'España'
      }

      return props.locationCountry.label
    })

    return {
      url,
      hasLocationWithNoLanding,

      shouldShowLocationParent,

      parentLabel,
      countryLabel,

      readableType,
    }
  }
})
</script>

<style lang="scss" scoped>
@import url('./search-result.scss');
</style>
