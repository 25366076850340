<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="currentSize.width"
    :height="currentSize.height"
    :viewBox="currentSize.viewbox"
    class="icon"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g
        fill="#FFF"
        fill-rule="nonzero"
      >
        <g v-if="size === 'sm'">
          <path
            d="M16.479 3.635c-.358-.08-.713.147-.792.505l-1.149 5.21c-.135.614-.666 1.043-1.29 1.043-.367 0-.664.297-.664.664 0 .366.297.664.664.664.603 0 1.193-.209 1.661-.587.467-.377.796-.909.926-1.497l1.15-5.21c.078-.359-.148-.713-.506-.792z"
            transform="translate(-667 -21) translate(667 21)"
          />
          <path
            d="M13.248 13.082h-8.7c-.365 0-.663-.298-.663-.664l-.001-.017v-.016c0-.366.299-.664.665-.664h5.379c.366 0 .664-.298.664-.664 0-.367-.298-.664-.664-.664H5.045l-.787-7.226-.001-.015C4.02 1.355 2.477 0 .664 0 .297 0 0 .297 0 .664c0 .367.297.664.664.664 1.146 0 2.123.855 2.275 1.99l.79 7.252c-.69.313-1.172 1.009-1.172 1.815v.033c0 1.098.893 1.992 1.992 1.992h.27c-.067.198-.104.41-.104.631 0 1.08.879 1.959 1.959 1.959s1.959-.879 1.959-1.959c0-.22-.037-.433-.105-.63h2.866c-.068.197-.105.41-.105.63 0 1.08.879 1.959 1.959 1.959s1.959-.879 1.959-1.959-.879-1.959-1.96-1.959zm-6.574 2.59c-.348 0-.631-.283-.631-.631 0-.348.283-.63.63-.63.349 0 .632.282.632.63 0 .348-.283.63-.631.63zm6.574 0c-.348 0-.63-.283-.63-.631 0-.348.282-.63.63-.63.348 0 .63.282.63.63 0 .348-.282.63-.63.63z"
            transform="translate(-667 -21) translate(667 21)"
          />
        </g>
        <g v-if="size === 'lg'">
          <path
            d="M29.08 6.414c-.632-.139-1.257.26-1.397.892l-2.028 9.195c-.239 1.083-1.175 1.839-2.276 1.839-.648 0-1.172.525-1.172 1.172 0 .647.524 1.172 1.172 1.172 1.064 0 2.105-.368 2.932-1.036.824-.665 1.404-1.603 1.633-2.642l2.028-9.195c.14-.632-.26-1.257-.892-1.397z"
            transform="translate(-77 -180) translate(77 180)"
          />
          <path
            d="M23.379 23.086H8.027c-.646 0-1.172-.526-1.172-1.172l-.001-.03.001-.029c0-.646.526-1.171 1.172-1.171h9.492c.648 0 1.172-.525 1.172-1.172 0-.648-.524-1.172-1.172-1.172H8.903L7.515 5.588l-.003-.026C7.096 2.392 4.37 0 1.172 0 .525 0 0 .525 0 1.172c0 .647.525 1.172 1.172 1.172 2.022 0 3.745 1.509 4.014 3.511L6.58 18.653c-1.218.553-2.067 1.78-2.067 3.202l.001.03-.001.029c0 1.939 1.577 3.516 3.515 3.516h.478c-.12.35-.185.723-.185 1.113C8.32 28.449 9.871 30 11.777 30c1.907 0 3.457-1.55 3.457-3.457 0-.39-.065-.764-.185-1.113h5.058c-.12.35-.185.723-.185 1.113 0 1.906 1.55 3.457 3.457 3.457 1.906 0 3.457-1.55 3.457-3.457 0-1.906-1.551-3.457-3.457-3.457zm-11.602 4.57c-.614 0-1.113-.5-1.113-1.113 0-.614.5-1.113 1.113-1.113.614 0 1.114.499 1.114 1.113s-.5 1.113-1.114 1.113zm11.602 0c-.614 0-1.114-.5-1.114-1.113 0-.614.5-1.113 1.114-1.113.614 0 1.113.499 1.113 1.113s-.5 1.113-1.113 1.113z"
            transform="translate(-77 -180) translate(77 180)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">

const sizes: Record<string, any> = {
  sm: {
    height: '17',
    width: '17',
    viewbox: '0 0 17 17',
  },
  lg: {
    height: '30',
    width: '30',
    viewbox: '0 0 30 30',
  },
}

export default defineNuxtComponent({
  props: {
    size: {
      type: String,
      validator: (prop: string) => Object.keys(sizes).includes(prop),
      default: 'sm',
    },
  },
  setup(props) {
    const currentSize = computed(() => sizes[props.size])
    return {
      currentSize,
    }
  },
})
</script>
