<template>
  <div class="tabs">
    <ul class="tabs__list">
      <template
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <SplNuxtLink
          v-if="tab.url"
          :to="tab.url"
          is-external
        >
          <li
            class="tabs__list__item"
            :class="tabSelectionClass(tab)"
            @click="emitInput(tab)"
          >
            <Lang
              :by-key="tab.name"
              capitalize-first
              plural
            />
          </li>
        </SplNuxtLink>

        <li
          v-else
          class="tabs__list__item"
          :class="tabSelectionClass(tab)"
          @click="emitInput(tab)"
        >
          <Lang
            :by-key="tab.name"
            capitalize-first
            plural
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../../shared/SplNuxtLink.vue'

import { type Tab } from './Tab'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
  },
  props: {
    tabs: { type: Array as PropType<Tab[]>, required: true },
    value: { type: Object as PropType<Tab>, required: true },
  },
  setup(props, { emit }) {
    function equals(a: string, b: string) {
      return a === b
    }

    const tabSelectionClass = (tab: Tab) => ({
      'tabs__list__item--selected': equals(props.value.id, tab.id),
    })

    const emitInput = (tab: Tab) => {
      if (!tab.url) {
        emit('input', tab)
      }
    }

    return {
      tabSelectionClass,
      emitInput,
    }
  },
})
</script>

<style lang="scss" scoped>
.tabs {
  @apply bg-black;
  @apply rounded-none;

  @screen md {
    @apply rounded-t;
  }

  &__list {
    @apply flex justify-center;
    @apply py-1;
    @screen lg {
      @apply py-0;
    }

    &__item {
      @apply text-white;
      @apply text-base;
      @apply mx-1;

      @apply py-4 px-4;
      @apply font-semibold;

      @apply cursor-pointer;
      @apply transition-colors duration-300 ease-in-out;

      @screen lg {
        @apply mx-2 px-6;

        @apply border-b-8;
        @apply border-black;
      }

      &--selected {
        @apply font-bold;

        @apply rounded-full;
        @apply border-1;

        @screen lg {
          @apply rounded-none;
          @apply border-0;
          @apply border-b-8;
          @apply border-white;
        }
      }
    }
  }
}
</style>
