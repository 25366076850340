import GA4AnaliticsEventBus from "~/core/events/ga4-analitics"

export function useMainFinder(label: string, emit: (name: string, value?: any) => void) {
  const searchQuery = ref('')
  const focused = ref(false)

  const route = useRoute()

  function onSearchClick() {
    focused.value = true
    emit('update:modelValue', true)
  }

  let searchingTimer: ReturnType<typeof setTimeout> | null = null

  function onInput() {
    if (!focused.value) {
      focused.value = true
      emit('update:modelValue', true)
    }

    // Search event
    if (searchingTimer) {
      clearTimeout(searchingTimer)
    }
    searchingTimer = setTimeout(() => {
      if (searchQuery.value) {
        GA4AnaliticsEventBus.send(label, {
          path: route.path,
          query: searchQuery.value,
        })
      }
    }, 1000)
  }

  function onBlur() {
    focused.value = false
    emit('update:modelValue', false)
  }

  function transformSearchText(searchText: string) {
    const lowCaseSearch = searchText.toLowerCase().trim()
    if (!lowCaseSearch) {
      return []
    }

    const notImportantKeyWords = ['spa', 'hotel']
    const finalQuery = lowCaseSearch.split(' ').sort((a, b) => {
      if (notImportantKeyWords.includes(a)) {
        return 1
      }

      if (notImportantKeyWords.includes(b)) {
        return -1
      }

      return b.length - a.length
    })

    return finalQuery
  }

  return {
    searchQuery,
    focused,

    onSearchClick,
    onInput,
    onBlur,

    transformSearchText,
  }
}
