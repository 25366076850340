<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="18" viewBox="0 0 27 18" class="icon">
    <g fill="none" fill-rule="evenodd">
      <g fill="#FFF">
        <g transform="translate(-359 -41) translate(359 41)">
          <rect width="27" height="4" rx="2" />
          <rect width="27" height="4" y="7" rx="2" />
          <rect width="27" height="4" y="14" rx="2" />
        </g>
      </g>
    </g>
  </svg>
</template>
