<template>
  <div class="main-menu">
    <div class="only-desktop-block">
      <MainMenuDesktop
        :lang-selector-items="langSelectorItems"
        :thanks="thanks"
        :home="home"
        :menu-primary="menuPrimary"
        :menu-secodary="menuSecodary"
      />
    </div>
    <div class="only-mobile">
      <MainMenuMobile
        :lang-selector-items="langSelectorItems"
        :menu-primary="menuPrimary"
        :menu-secodary="menuSecodary"
      />
    </div>

    <div :class="{'main-finder__wrapper': finderMarginUp}">
      <slot name="before-finder" />
      <div
        v-if="withFinder"
        class="main-finder"
      >
        <MainFinder :default-selected-tab="home ? 'spa' : ''" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MainMenuDesktop from './MainMenuDesktop.vue'
import MainMenuMobile from './MainMenuMobile.vue'

import MainFinder from '../main-finder/MainFinder.vue'

import esMenuPrimary from './content/es-menu-primary.json'
import ptMenuPrimary from './content/pt-menu-primary.json'

import menuSecondary from './content/menu-secondary.json'
import { translate } from '~/core/ts/util/translate'
import { useLangSelector } from '~/core/composable/shared/useLangSelector'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'

export default defineNuxtComponent({
  components: {
    MainMenuDesktop,
    MainMenuMobile,
    MainFinder,
  },
  props: {
    thanks: {
      type: Boolean,
      default: false,
    },
    home: {
      type: Boolean,
      default: false,
    },
    withFinder: {
      type: Boolean,
      default: false
    },
    // Only desktop
    finderMarginUp: {
      type: Boolean,
      default: false
    },
  },
  async setup() {
    const route = useRoute()
    const {
      generateLangMenuItemsFromURL,
      generateLangMenuItems,
    } = useLangSelector()

    const menuPrimary = translate({
      es: esMenuPrimary,
      pt: ptMenuPrimary,
    })
    const menuSecodary = menuSecondary

    async function fetchOrGenerateLangMenuItems() {
      if (route.path.includes('/voucher/') || route.path.includes('/purchase/')) {
        return generateLangMenuItemsFromURL(route.path)
      }

      const urlsByLang = await useFetchProxy<Record<string, string>>(
        '/api/shared/get-lang-alter-urls-by-uri',
        {
          // server: false,
          method: 'post', body: { uri: route.path }
        }
      )

      return generateLangMenuItems(urlsByLang)
    }

    const langSelectorItems = await fetchOrGenerateLangMenuItems()

    return {
      menuPrimary,
      menuSecodary,

      langSelectorItems,
    }
  }
})
</script>

<style lang="scss" scoped>
.main-menu {
  @apply bg-transparent;
}
.main-finder {
  &__wrapper {
    @screen md {
      @apply pt-2;
      @apply -mt-28;
    }
  }
}

</style>
