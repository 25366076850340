<template>
  <svg
    width="27px"
    height="18px"
    viewBox="0 0 27 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>9619E766-4870-4B2E-9016-377701087F7B</title>
    <g id="V02" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="7_2_1spalopia-marketplace_results_menuScroll"
        transform="translate(-1333.000000, -41.000000)"
        fill="#FFFFFF"
      >
        <g id="Group-3" transform="translate(1333.000000, 41.000000)">
          <rect id="Rectangle" x="0" y="0" width="27" height="4" rx="2"></rect>
          <rect id="Rectangle" x="0" y="7" width="27" height="4" rx="2"></rect>
          <rect id="Rectangle" x="0" y="14" width="27" height="4" rx="2"></rect>
        </g>
      </g>
    </g>
  </svg>
</template>
