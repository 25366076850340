<template>
  <div
    v-click-outside="onBlur"
    class="treatment-finder"
    :class="{ focused }"
  >
    <div class="treatment-finder__searcher">
      <div class="treatment-finder__input-box">
        <div class="treatment-finder__title">
          <Lang
            by-key="treatment"
            plural
            capitalize-first
          />
        </div>

        <input
          v-model="searchQuery"
          class="treatment-finder__input"
          type="text"
          :placeholder="searchPlaceHolder"
          @input="onInput"
          @click="onSearchClick"
        >
      </div>
      <div
        v-if="focused"
        class="treatment-finder__icon"
        @click="onBlur"
      >
        <TimesIcon
          variant="dark"
          :size="16"
        />
      </div>

      <div
        v-else
        class="treatment-finder__icon"
      >
        <SearchIcon
          variant="dark"
          :size="16"
        />
      </div>
    </div>

    <!-- Search result -->
    <div
      v-if="focused"
      class="treatment-finder__search-result only-desktop-block"
    >
      <div
        v-for="(result, i) in searchResults"
        :key="getKeyFromSearchResultItem(result, i)"
      >
        <TreatmentSearchResult
          :treatment="result.treatment"
          :treatment-parent="getPosibleTreatmentParent(result.treatment)"
        />
      </div>
    </div>

    <ConfigModalMobile
      v-model="focused"
      class="only-mobile"
    >
      <template #header>
        <SpalopiaIcon />
      </template>
      <div>
        <div class="treatment-finder__mobile__searcher">
          <p class="treatment-finder__mobile__searcher__title">
            <Lang
              by-key="treatment"
              plural
              capitalize-first
            />
          </p>
          <input
            v-model="searchQuery"
            class="treatment-finder__mobile__input"
            type="text"
            :placeholder="searchPlaceHolder"
          >
        </div>
        <div class="treatment-finder__mobile__search-result">
          <div
            v-for="(result, i) in searchResults"
            :key="getKeyFromSearchResultItem(result, i)"
          >
            <TreatmentSearchResult
              :treatment="result.treatment"
              :treatment-parent="getPosibleTreatmentParent(result.treatment)"
            />
          </div>
        </div>
      </div>
    </ConfigModalMobile>
  </div>
</template>

<script lang="ts">
import ConfigModalMobile from '../shared/form/ConfigModalMobile.vue'

import TreatmentSearchResult from './result/TreatmentSearchResult.vue'

import SearchIcon from '../shared/icons/Search.icon.vue'
import TimesIcon from '../shared/icons/Times.icon.vue'
import SpalopiaIcon from '../shared/icons/Spalopia.icon.vue'

import { useMainFinder } from '~/core/composable/main-finder/useMainFinder'

import { TreatmentMinified } from '~/core/ts/entity/Treatment'

import { includesInsensitive, includesWordInsensitive } from '~/core/ts/util/string'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { translateByKey } from '~/core/ts/util/translate'

type SearchResult = { treatment: TreatmentMinified, score: number }

export default defineNuxtComponent({
  components: {
    ConfigModalMobile,
    TreatmentSearchResult,
    SearchIcon,
    TimesIcon,
    SpalopiaIcon,
  },
  props: {
    // "is focused" model value
    modelValue: {
      type: Boolean,
      default: false
    },
  },
  async setup(props, { emit }) {
    const {
      searchQuery,
      focused,

      onSearchClick,
      onInput,
      onBlur,

      transformSearchText,
    } = useMainFinder('treatments_search', emit)

    const treatments: Array<TreatmentMinified> = await useFetchProxy<Array<TreatmentMinified>>(
      '/api/main-finder/get-treatments-minified', {}
    )

    function getPosibleTreatmentParent(treatment: TreatmentMinified): TreatmentMinified | null {
      if (!treatment.uuidParents.length) {
        return null
      }

      for (const treat of treatments) {
        if (treatment.uuidParents.includes(treat.uuid)) {
          return treat
        }
      }

      return null
    }

    const searchResults = computed<Array<SearchResult>>(() => {
      const query = transformSearchText(searchQuery.value)
      if (!query.length) {
        return treatments
          .filter((treatment) => !treatment.uuidParents.length)
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((treatment) => ({
            treatment,
            score: 0
          }))
      }

      let hasParentTreatment = false

      const finalResult: Array<SearchResult> = []

      // Búsqueda de localizaciones, cuantas más palabras de la query encontremos, más nota recibe. 'score'
      const treatmentResultDict: Record<string, { score: number, treatment: TreatmentMinified }> = {}
      query.forEach((queryPart, i) => {
        treatments.forEach((treatment) => {
          if (includesInsensitive(treatment.title, queryPart)) {
            if (!treatmentResultDict[treatment.uuid]) {
              treatmentResultDict[treatment.uuid] = { score: 0, treatment: treatment }
            }

            treatmentResultDict[treatment.uuid].score += queryPart.length / (i + 1)
            if (!treatment.uuidParents.length) {
              hasParentTreatment = true
              treatmentResultDict[treatment.uuid].score += 2
            }
          }

          if (includesWordInsensitive(treatment.title, queryPart)) {
            if (!treatmentResultDict[treatment.uuid]) {
              treatmentResultDict[treatment.uuid] = { score: 0, treatment: treatment }
            }

            treatmentResultDict[treatment.uuid].score += queryPart.length * 2 / (i + 1)
            if (!treatment.uuidParents.length) {
              hasParentTreatment = true
              treatmentResultDict[treatment.uuid].score += 2
            }
          }
        })
      })

      // Añadimos los resultados de búsqueda de tratamientos a la lista final
      Object.values(treatmentResultDict)
        .filter(({score}) => !!score)
        .forEach((locResult) => {
          finalResult.push({
            treatment: locResult.treatment,
            score: locResult.score
          })
        })

      if (finalResult.length < 4 && hasParentTreatment) {
        const parentTreatmentResultUUIDs = finalResult
          .filter((treat) => !treat.treatment.uuidParents.length)
          .map((treat) => {
            return {
              uuid: treat.treatment.uuid,
              score: treat.score,
            }
          })

        // Añadimos los tratamientos hijos, si en los resultados tenemos algún tratamiento padre.
        for (const treat of treatments) {
          for (const result of parentTreatmentResultUUIDs) {
            if (treat.uuidParents.includes(result.uuid)) {
              finalResult.push({
                treatment: treat,
                score: result.score
              })
            }
          }
        }
      }

      return finalResult
        .sort((a, b) => b.score - a.score)
        .slice(0, 12)
    })

    function getKeyFromSearchResultItem(searchResult: SearchResult, index: number): string {
      if (searchResult.treatment) {
        return `treatment-search-result-${searchResult.treatment.uuid}`
      }

      return `treatment-search-result-${index}`
    }

    const searchPlaceHolder = translateByKey('search-by-treatment')

    return {
      focused,

      searchQuery,
      searchResults,
      getKeyFromSearchResultItem,
      getPosibleTreatmentParent,

      onSearchClick,
      onInput,
      onBlur,

      // getLocationParent,

      // Textos
      searchPlaceHolder,
    }
  }
})
</script>

<style lang="scss" scoped>
.treatment-finder {
  @apply bg-white;

  @screen lg {
    @apply bg-spl-gray-1;
  }

  &__searcher {
    @apply pl-12;
    @apply pr-8;
    @apply py-4;

    @apply flex justify-between;

    @apply border-2;
    @apply border-spl-gray-1;
  }

  &__input-box {
    @apply w-full;
  }

  &__title {
    @apply font-bold;
    @apply text-spl-dark;

    @apply pl-2;
    @apply text-sm;

    @apply hidden;
  }

  &__input {
    @apply w-full;
    @apply p-2;

    @apply bg-transparent;
    @apply border-0;
  }

  &__icon {
    @apply bg-white;
    @apply rounded-full;

    @apply ml-4;
    @apply px-6;

    @apply flex justify-center items-center;
  }

  @screen lg {
    &__title {
      @apply block;
    }
  }

  &__search-result {
    max-height: 65vh;
    @apply overflow-y-scroll;
  }

  &__mobile {
    &__searcher {
      @apply mt-2 mb-4;
      @apply text-center;

      &__title {
        @apply font-bold;
        @apply text-spl-dark;
      }
    }

    &__input {
      @apply w-full;
      @apply p-2;
      @apply my-2;

      @apply border border-spl-gray-1;
    }
  }
}

.treatment-finder.focused {
  @apply bg-white;
}
</style>
