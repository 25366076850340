<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      :fill="color"
      fill-rule="evenodd"
    >
      <path
        d="M13.654 3.304C12.24 1.204 9.996 0 7.5 0 5.005 0 2.762 1.204 1.346 3.304-.062 5.392-.387 8.036.474 10.373c.231.638.598 1.258 1.087 1.838l5.446 6.555A.642.642 0 0 0 7.5 19c.19 0 .37-.085.494-.234l5.444-6.553a6.079 6.079 0 0 0 1.086-1.836 7.835 7.835 0 0 0-.87-7.073zm-.344 6.608a4.762 4.762 0 0 1-.858 1.439L7.5 17.31l-4.955-5.964a4.765 4.765 0 0 1-.856-1.44 6.48 6.48 0 0 1 .724-5.848C3.583 2.324 5.437 1.33 7.5 1.33c2.063 0 3.918.995 5.088 2.73a6.48 6.48 0 0 1 .722 5.853z"
      />
      <path
        d="M7.5 4C5.57 4 4 5.57 4 7.5S5.57 11 7.5 11 11 9.43 11 7.5 9.43 4 7.5 4zm0 5.75c-1.24 0-2.25-1.01-2.25-2.25S6.26 5.25 7.5 5.25 9.75 6.26 9.75 7.5 8.74 9.75 7.5 9.75z"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 23
    },
    variant: {
      type: String,
      default: 'dark' // light, dark
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#151515'
      }
      if (props.variant === 'light') {
        return '#fafafa'
      }

      // Default: light
      return '#fafafa'
    })()

    const width = props.size
    const height = Math.ceil(props.size * (19 / 15))

    const viewBox = `0 0 16 20`

    return {
      width,
      height,
      color,
      viewBox,
    }
  }
})
</script>
