<template>
  <div
    v-click-outside="onBlur"
    class="spa-location-finder"
    :class="{ focused }"
  >
    <div class="spa-location-finder__searcher">
      <div class="spa-location-finder__input-box">
        <div class="spa-location-finder__title">
          <Lang
            by-key="destination"
            capitalize-first
          />
        </div>

        <input
          v-model="searchQuery"
          class="spa-location-finder__input"
          type="text"
          :placeholder="searchPlaceHolder"
          @input="onInput"
          @click="onSearchClick"
        >
      </div>
      <div
        v-if="focused"
        class="spa-location-finder__icon"
        @click="onBlur"
      >
        <TimesIcon
          variant="dark"
          :size="16"
        />
      </div>

      <div
        v-else
        class="spa-location-finder__icon"
      >
        <SearchIcon
          variant="dark"
          :size="16"
        />
      </div>
    </div>

    <!-- Search result -->
    <div
      v-if="focused"
      class="spa-location-finder__search-result only-desktop-block"
    >
      <div
        v-for="(result, i) in searchResults"
        :key="getKeyFromSearchResultItem(result, i)"
      >
        <LocationSearchResult
          v-if="result.local"
          :location="result.local"
          :location-parent="getLocationParent(result.local)"
          :location-country="getLocalizationCountry(result.local)"
        />

        <SpaSearchResult
          v-else-if="result.spa"
          :spa="result.spa"
        />
      </div>
    </div>

    <ConfigModalMobile
      v-model="focused"
      class="only-mobile"
    >
      <template #header>
        <SpalopiaIcon />
      </template>
      <div>
        <div class="spa-location-finder__mobile__searcher">
          <p class="spa-location-finder__mobile__searcher__title">
            <Lang
              by-key="destination"
              capitalize-first
            />
          </p>
          <input
            v-model="searchQuery"
            class="spa-location-finder__mobile__input"
            type="text"
            :placeholder="searchPlaceHolder"
          >
        </div>
        <div class="spa-location-finder__mobile__search-result">
          <div
            v-for="(result, i) in searchResults"
            :key="getKeyFromSearchResultItem(result, i)"
          >
            <LocationSearchResult
              v-if="result.local"
              :location="result.local"
              :location-parent="getLocationParent(result.local)"
              :location-country="getLocalizationCountry(result.local)"
            />

            <SpaSearchResult
              v-else-if="result.spa"
              :spa="result.spa"
            />
          </div>
        </div>
      </div>
    </ConfigModalMobile>
  </div>
</template>

<script lang="ts">
import SpaSearchResult from './result/SpaSearchResult.vue'
import LocationSearchResult from './result/LocationSearchResult.vue'
import ConfigModalMobile from '../shared/form/ConfigModalMobile.vue'

import SearchIcon from '../shared/icons/Search.icon.vue'
import TimesIcon from '../shared/icons/Times.icon.vue'
import SpalopiaIcon from '../shared/icons/Spalopia.icon.vue'

import { useMainFinder } from '~/core/composable/main-finder/useMainFinder'

import { SpaMinified } from '~/core/ts/entity/Spa'
import { LocationCollection, LocationMinified } from '~/core/ts/entity/Location'

import { includesInsensitive, includesWordInsensitive } from '~/core/ts/util/string'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { translateByKey } from '~/core/ts/util/translate'

type SearchResult = {
  spa: SpaMinified | null,
  local: LocationMinified | null,
  score: number,
}

export default defineNuxtComponent({
  components: {
    SpaSearchResult,
    LocationSearchResult,
    ConfigModalMobile,
    SearchIcon,
    TimesIcon,
    SpalopiaIcon,
  },
  props: {
    // "is focused" model value
    modelValue: {
      type: Boolean,
      default: false
    },
  },
  async setup(props, { emit }) {
    const runtime = useRuntimeConfig()

    const {
      searchQuery,
      focused,

      onSearchClick,
      onInput,
      onBlur,

      transformSearchText,
    } = useMainFinder('spas_and_locations_search', emit)

    const [spas, locations] = await Promise.all([
      useFetchProxy<SpaMinified[]>('/api/main-finder/get-spas-minified', {}),
      useFetchProxy<LocationMinified[]>('/api/main-finder/get-locations-minified', {}),
    ])

    const locationCollection = new LocationCollection(locations)

    function getLocationParent(loc: LocationMinified): LocationMinified | null {
      return locationCollection.getParentFrom(loc)
    }

    function getLocalizationCountry(loc: LocationMinified): LocationMinified | null {
      return locationCollection.getCountryFrom(loc)
    }

    const searchableLocations = locations
      .filter((loc) => loc.type !== 'country')
      .filter((loc) => {
        // TODO: DEV. LOCAL. Activar todas las localizaciones para PROD también
        //            Esto se hizo para meter las localizaciones de Portugal aunque no tengan landing.
        if (runtime.public.isDev) {
          return true
        }

        return loc.hasAValidLanding
      })

    const searchResults = computed(() => {
      const query = transformSearchText(searchQuery.value)
      if (!query.length) {
        return searchableLocations
          .filter((loc) => (!loc.parentUUID || loc.type === 'community'))
          .map((loc) => {
            return {
              spa: null,
              local: loc,
              score: 0
            }
          })
      }

      const finalResult: Array<SearchResult> = []

      // Búsqueda de localizaciones, cuantas más palabras de la query encontremos, más nota recibe. 'score'
      const locationResultDict: Record<string, { score: number, loc: LocationMinified }> = {}
      query.forEach((queryPart, i) => {
        searchableLocations.forEach((loc) => {
          if (includesInsensitive(loc.label, queryPart)) {
            if (!locationResultDict[loc.uuid]) {
              locationResultDict[loc.uuid] = { score: 0, loc: loc }
            }

            locationResultDict[loc.uuid].score += queryPart.length / (i + 1)
          }

          if (includesWordInsensitive(loc.label, queryPart)) {
            if (!locationResultDict[loc.uuid]) {
              locationResultDict[loc.uuid] = { score: 0, loc: loc }
            }

            locationResultDict[loc.uuid].score += queryPart.length * 2 / (i + 1)
          }
        })
      })

      // Añadimos los resultados de búsqueda de localizaciones a la lista final
      Object.values(locationResultDict)
        .filter(({score}) => !!score)
        .forEach((locResult) => {
          finalResult.push({
            spa: null,
            local: locResult.loc,
            score: locResult.score
          })
        })

      // Búsqueda de spas, cuantas más palabras de la query encontremos, más nota recibe. 'score'
      const spasResultDict: Record<string, { score: number, spa: SpaMinified }> = {}
      query.forEach((queryPart, i) => {
        spas.forEach((spa) => {
          let shouldSearchHotel = !!spa.hotelName
          if (includesInsensitive(spa.name, queryPart)) {
            if (!spasResultDict[spa.uuid]) {
              spasResultDict[spa.uuid] = { score: 0, spa }
            }

            spasResultDict[spa.uuid].score += queryPart.length / (i + 1)
            shouldSearchHotel = false
          }

          if (includesWordInsensitive(spa.name, queryPart)) {
            if (!spasResultDict[spa.uuid]) {
              spasResultDict[spa.uuid] = { score: 0, spa }
            }

            spasResultDict[spa.uuid].score += queryPart.length * 2 / (i + 1)
            shouldSearchHotel = false
          }

          if (shouldSearchHotel && includesInsensitive(spa.hotelName, queryPart)) {
            if (!spasResultDict[spa.uuid]) {
              spasResultDict[spa.uuid] = { score: 0, spa }
            }

            spasResultDict[spa.uuid].score += queryPart.length / (i + 1)
          }

          if (shouldSearchHotel && includesWordInsensitive(spa.hotelName, queryPart)) {
            if (!spasResultDict[spa.uuid]) {
              spasResultDict[spa.uuid] = { score: 0, spa }
            }

            spasResultDict[spa.uuid].score += queryPart.length * 2 / (i + 1)
          }
        })
      })

      // Añadimos los resultados de búsqueda de spas a la lista final.
      Object.values(spasResultDict)
        .filter(({score}) => !!score)
        .forEach((spaResult) => {
          finalResult.push({
            spa: spaResult.spa,
            local: null,
            score: spaResult.score,
          })
        })

      return finalResult
        .sort((a, b) => b.score - a.score)
        .slice(0, 12)
    })

    function getKeyFromSearchResultItem(searchResult: SearchResult, index: number): string {
      if (searchResult.local) {
        return `spa-loc-search-result-${searchResult.local.uuid}`
      }

      if (searchResult.spa) {
        return `spa-loc-search-result-${searchResult.spa.uuid}`
      }

      return `spa-loc-search-result-${index}`
    }

    const searchPlaceHolder = translateByKey('search-by-location-or-spa')

    return {
      searchQuery,
      focused,
      searchResults,
      getKeyFromSearchResultItem,
      onSearchClick,
      onInput,
      onBlur,

      getLocationParent,
      getLocalizationCountry,

      // Textos
      searchPlaceHolder,
    }
  }
})
</script>

<style lang="scss" scoped>
.spa-location-finder {
  @apply bg-white;

  @screen lg {
    @apply bg-spl-gray-1;
  }

  &__searcher {
    @apply pl-12;
    @apply pr-8;
    @apply py-4;

    @apply flex justify-between;

    @apply border-2;
    @apply border-spl-gray-1;
  }

  &__input-box {
    @apply w-full;
  }

  &__title {
    @apply font-bold;
    @apply text-spl-dark;

    @apply pl-2;
    @apply text-sm;

    @apply hidden;
  }

  &__input {
    @apply w-full;
    @apply p-2;

    @apply bg-transparent;
    @apply border-0;
  }

  &__icon {
    @apply bg-white;
    @apply rounded-full;

    @apply ml-4;
    @apply px-6;

    @apply flex justify-center items-center;
  }

  @screen lg {
    &__title {
      @apply block;
    }
  }

  &__search-result {
    max-height: 65vh;
    @apply overflow-y-scroll;
  }

  &__mobile {
    &__searcher {
      @apply mt-2 mb-4;
      @apply text-center;

      &__title {
        @apply font-bold;
        @apply text-spl-dark;
      }
    }

    &__input {
      @apply w-full;
      @apply p-2;
      @apply my-2;

      @apply border border-spl-gray-1;
    }
  }
}

.spa-location-finder.focused {
  @apply bg-white;
}
</style>
