<template>
  <div class="footer-desktop">
    <div class="footer-desktop__text">
      <div class="footer-desktop__text__slogan">
        <div class="footer-desktop__text__slogan__content">
          <h2 class="footer-desktop__text__slogan__content__item">
            <DynLang
              by-key="booking"
              plural
              capitalize-all
            />,
          </h2>
          <h2 class="footer-desktop__text__slogan__content__item">
            <DynLang
              by-key="spa-gift-voucher"
              capitalize-all
            /> &
          </h2>
          <h2 class="footer-desktop__text__slogan__content__item">
            <DynLang
              by-key="stay"
              capitalize-all
            />
          </h2>
        </div>
      </div>
      <div class="footer-desktop__text__description">
        <DynLang :by-raw="desc" />
      </div>
    </div>
    <div class="footer-desktop__content">
      <div class="footer-desktop__content__links">
        <div class="slogan">
          <div>
            <img
              src="~/assets/images/mobile/sloganImage.svg"
              class="menu-body__footer_slogan__image"
            >
            <div class="slogan__text">
              <p>
                <DynLang
                  by-key="relax"
                  capitalize-all
                />
              </p>
              <p>
                <DynLang
                  by-key="connect"
                  capitalize-all
                />
              </p>
              <p>
                <DynLang
                  by-key="share"
                  capitalize-all
                />
              </p>
            </div>
          </div>
        </div>
        <div class="footer-desktop__content__links__wrapper">
          <DesktopLinkCollection
            :content="content"
            class="footer-desktop__content__links__wrapper__collection"
          />
        </div>
      </div>
    </div>
    <div class="footer-desktop__social-media">
      <div class="footer-desktop__social-media__icons">
        <div class="social-media">
          <a
            href="https://www.facebook.com/Spalopia/"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="social-media__item"
              src="~/assets/images/mobile/facebook.svg"
            >
          </a>
          <a
            :href="gramsLink"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="social-media__item"
              src="~/assets/images/mobile/instagram.svg"
            >
          </a>
          <a
            href="https://www.linkedin.com/company/spalopia"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="social-media__item"
              src="~/assets/images/mobile/linkedin.svg"
            >
          </a>
        </div>
      </div>
      <p class="footer-desktop__social-media__phone">
        <DynLang by-key="contact-us" />
      </p>
    </div>
    <div class="footer-desktop__sponsors">
      <div class="footer-desktop__sponsors__main">
        <div class="sponsors">
          <img src="~/assets/images/mobile/visa.svg">
          <img src="~/assets/images/mobile/mastercard.svg">
          <img src="~/assets/images/mobile/pay-pal.svg">
        </div>
        <div class="sponsors">
          <img src="~/assets/images/mobile/ssl.png">
        </div>
      </div>
      <div class="footer-desktop__sponsors__secondary">
        <div class=" sponsors">
          <img src="~/assets/images/goverment.png">
        </div>
      </div>
    </div>
    <p class="footer-desktop__license">
      <DynLang by-key="footer-company-code" />: I-AV-0004809.1
    </p>
  </div>
</template>

<script lang="ts">
import DesktopLinkCollection from '../shared/link-collection/DesktopLinkCollection.vue'

import { translate } from '@/core/ts/util/translate'

export default defineNuxtComponent({
  components: {
    DesktopLinkCollection,
  },
  props: {
    content: {
      type: Array<any>,
      default: () => []
    }
  },
  setup() {
    const desc = {
      es: 'En Spalopia encontrarás los mejores precios para disfrutar de una experiencia inolvidable en el Spa y centros wellness. Masajes, Rituales, Chocolaterapias, Dayspas, Spa con estancia o Spa con Brunch son algunos de los servicios y tratamientos que podrás seleccionar en los más de 300 spas y centros que trabajan con nosotros alrededor de toda España. Si ya sabes cuando quieres ir al Spa, puedes reservar directamente la fecha y la hora que más te interesa para planificar tu bienestar de una manera sencilla y cómoda con Spalopia. Tenemos miles de ofertas y promociones de Spa para ti y contamos con una gran variedad de servicios disponibles para regalar a tu ser querido a un precio único. Todos nuestros bonos y reservas de tratamientos de spas tienen la seguridad y confianza respaldada por Spalopia, la única web en toda España que tiene el objetivo de mejorar tu bienestar en el Spa.',
      pt: 'Na Spalopia encontra os melhores preços para desfrutar de uma experiência inesquecível num spa ou centro de bem-estar. Massagens, Rituais, Chocolaterapias, Dayspas, Spa com estadia ou Spa com Brunch são alguns dos serviços e tratamentos que pode escolher entre os mais de 300 spas e centros que trabalham connosco em toda a Espanha. Se já sabe quando quer ir ao Spa, pode reservar diretamente a data e a hora que mais lhe interessa para planear o seu bem-estar de forma simples e cómoda com a Spalopia. Temos milhares de ofertas e promoções de Spa para si e temos uma grande variedade de serviços disponíveis para oferecer ao seu ente querido a um preço único. Todos os nossos vouchers e reservas de tratamentos de Spa têm a segurança e a confiança da Spalopia, o único site em Espanha que visa melhorar o seu bem-estar no Spa.',
    }

    const gramsLink = translate({
      es: 'https://www.instagram.com/Spalopia/',
      pt: 'https://www.instagram.com/Spalopiapt/',
    })

    return {
      gramsLink,
      desc,
    }
  },
})
</script>

<style lang="scss" scoped>
.social-media {
  @apply flex justify-center;
  &__item {
    @apply mx-1.5;
  }
}
.slogan {
  // @apply mt-12;
  @apply flex justify-center;
  @apply text-xl;
  &__text {
    @apply mt-8;
    letter-spacing: 5.25px;
    @apply text-white;
  }
}
.sponsors {
  @apply flex justify-around;
}
.footer-desktop {
  @apply bg-spl-dark;

  &__text {
    @apply flex;
    &__slogan {
      @apply w-1/2;
      @apply bg-spl-acent;

      @apply flex justify-center items-center;
      &__content__item {
        @apply text-spl-acent-dark text-2xl;
        @apply font-bold;
      }
    }
    &__description {
      @apply w-1/2;
      @apply bg-spl-secondary;
      @apply p-28;
      @apply text-sm;
      @apply text-spl-secondary-dark;
      @apply font-bold;
    }
  }

  &__content {
    &__links {
      @apply flex;
      @apply px-44;
      @apply mt-8;
      &__wrapper {
        @apply flex-grow;
        @apply pb-5;

        &__collection {
          @apply font-bold;
        }
      }
    }
    &__newsletter {
      @apply bg-spl-secondary-dark;
      @apply p-10;
      @apply text-sm;
      @apply flex;
      @apply w-3/4;
      @apply mx-auto;
      @apply mt-2;
      &__text {
        @apply bg-white;
        @apply text-spl-secondary-dark;

        @apply px-1;
        @apply py-3;
        @apply font-bold;
        @apply flex-grow;
        @apply rounded;
      }
      &__button {
        @apply ml-2;
        @apply rounded;
        @apply bg-spl-secondary;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
        @apply text-spl-dark;
        @apply px-9 py-3;
        @apply font-bold;
        @apply uppercase;
      }
    }
  }

  &__social-media {
    @apply flex justify-center;
    @apply mt-8;
    @apply mb-6;

    @apply pb-10;
    &__phone {
      @apply text-base;
      @apply text-spl-acent;
      @apply font-bold;
      @apply uppercase;
      @apply ml-3;
    }
  }
  &__sponsors {
    @apply bg-white;
    @apply px-28;
    @apply py-5;
    &__main {
      @apply flex justify-between;
      @apply mt-1 mb-1;
      &__payment {
        @apply w-1/5;
      }
    }
    &__secondary {
      @apply flex;
      @apply mt-5;
      @apply pb-8;
    }
  }
  &__license {
    @apply px-28;
    @apply bg-white;
    @apply text-gray-400;
    @apply text-sm;
  }
}
</style>
