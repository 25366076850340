<template>
  <div>
    <div class="only-desktop-block">
      <FooterDesktop :content="footerContent" />
    </div>
    <div class="only-mobile">
      <FooterMobile />
    </div>
    <div
      v-if="versionTag"
      class="version"
    >
      Versión: {{ versionTag }}
    </div>
  </div>
</template>

<script lang="ts">
import FooterDesktop from './FooterDesktop.vue'
import FooterMobile from './FooterMobile.vue'

import footerPrimary from './footer-primary.json'

export default defineNuxtComponent({
  components: {
    FooterDesktop,
    FooterMobile,
  },
  setup() {
    const footerContent = footerPrimary

    const runtime = useRuntimeConfig()
    const versionTag = runtime.public.versionTag

    return {
      footerContent,
      versionTag,
    }
  }
})
</script>

<style lang="scss" scoped>
.version {
  @apply text-right;
  @apply text-sm;
  @apply pr-2;
}
</style>
