<template>
  <div class="footer-mobile">
    <div class="footer-mobile__slogan">
      <p>
        <DynLang
          by-key="booking"
          plural
          capitalize-all
        />,
      </p>
      <p>
        <DynLang
          by-key="spa-gift-voucher"
          capitalize-all
        /> & <DynLang
          by-key="stay"
          capitalize-all
        />
      </p>
    </div>
    <div class="footer-mobile__newsletter">
      <button class="footer-mobile__newsletter__button">
        <DynLang by-key="receive-offers" />
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.footer-mobile {
  &__slogan {
    @apply bg-spl-acent;
    @apply text-spl-acent-dark;
    @apply font-bold;
    @apply text-xl;
    @apply leading-7;
    @apply py-11;
    @apply px-9;
  }
  &__newsletter {
    @apply bg-spl-secondary-dark;
    @apply py-12;
    @apply px-7;
    &__button {
      @apply text-sm text-spl-secondary-dark;
      @apply py-4;
      @apply px-5;
      @apply font-bold;
      @apply bg-white;
      @apply w-full;
    }
  }
}
</style>
