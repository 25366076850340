
export type LocationDTO = {
  uuid: string,
  locality_parents: Array<string>,
  label: string,
  descriptions: Array<{langcode: string, long_description: string, short_description: string}>,
  latitude: number,
  longitude: number,
  type: string,
  images: Array<any>,
  slug: string,
  metadata: any,

  uuid_parent: string,
  political_parent: LocationDTO | undefined,

  payload: string[],
}

export class Location {
  constructor(
    public uuid: string,
    public label: string,
    public parentUUID: string | null,
    public descriptions: Array<{langcode: string, longDescription: string, shortDescription: string}>,
    public type: string,
    public latitude: number,
    public longitude: number,
    public slug: string,
    public hasAValidLanding: boolean,
  ) {}

  static from(dto: LocationDTO): Location {
    const descriptions = dto.descriptions.map((descDTO) => {
      return {
        langcode: descDTO.langcode,
        longDescription: descDTO.long_description,
        shortDescription: descDTO.short_description,
      }
    })

    let parentUUID = dto.political_parent ? dto.political_parent.uuid : null
    if (!parentUUID && dto.uuid_parent) {
      parentUUID = dto.uuid_parent
    }

    return new Location(
      dto.uuid,
      dto.label,
      parentUUID,
      descriptions,
      dto.type,
      dto.latitude,
      dto.longitude,
      dto.slug,
      true
    )
  }
}

export class LocationMinified {
  constructor(
    public uuid: string,
    public parentUUID: string | null,
    public label: string,
    public slug: string,
    public type: string,
    public hasAValidLanding: boolean,
  ) {}

  static fromLocation(dto: Location, hasAValidLanding: boolean) {
    return new LocationMinified(
      dto.uuid,
      dto.parentUUID,
      dto.label,
      dto.slug,
      dto.type,
      hasAValidLanding,
    )
  }
}

export type LocationTree = {
  location: Location;
  childLocations: Array<LocationTree>;
}

type LocationCompo = LocationMinified | Location

export class LocationCollection {
  private locationByUUID: Record<string, LocationCompo>

  constructor(locations: Array<LocationCompo>) {
    this.locationByUUID = {}
    locations.forEach((loc) => {
      this.locationByUUID[loc.uuid] = loc
    })
  }

  public getParentFrom(loc: LocationCompo): LocationCompo | null {
    if (!loc.parentUUID) {
      return null
    }

    const maybe = this.locationByUUID[loc.parentUUID]
    if (!maybe) {
      return null
    }
    return maybe
  }

  public getCountryFrom(loc: LocationCompo): LocationCompo {
    let cursor = loc

    while (cursor && cursor.parentUUID && cursor.type !== 'country') {
      cursor = this.locationByUUID[cursor.parentUUID]
    }

    return cursor
  }
}
