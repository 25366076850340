<template>
  <SplNuxtLink
    is-external
    :to="url"
  >
    <div class="search-result">
      <div class="search-result__icon">
        <GivingIcon
          variant="dark"
          :size="20"
        />
      </div>
      <div class="search-result__labels">
        <div class="search-result__labels__header">
          {{ treatment.title }}
        </div>
        <div
          v-if="treatmentParent"
          class="search-result__labels__parent"
        >
          {{ treatmentParent.title }}
        </div>
        <div
          v-else
          class="search-result__labels__parent"
        >
          Tratamientos
        </div>
      </div>
    </div>
  </SplNuxtLink>
</template>

<script lang="ts">
import SplNuxtLink from '../../shared/SplNuxtLink.vue'
import GivingIcon from '../../shared/icons/Giving.icon.vue'

import type { TreatmentMinified } from '~/core/ts/entity/Treatment'

import config from '~/core/ts/server-config'
import { joinPaths } from '~/core/ts/util/string'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    GivingIcon,
  },
  props: {
    treatment: {
      type: Object as PropType<TreatmentMinified>,
      required: true
    },
    treatmentParent: {
      type: Object as PropType<TreatmentMinified | null>,
      default: () => null,
    }
  },
  setup(props) {
    const url = computed(() => {
      if (props.treatmentParent) {
        return joinPaths(props.treatmentParent.slug, props.treatment.slug)
      }

      return props.treatment.slug
    })

    return {
      url,
    }
  }
})
</script>

<style lang="scss" scoped>
@import url('./search-result.scss');
</style>
