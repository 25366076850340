<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 15 16"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g
        :fill="color"
        fill-rule="nonzero"
      >
        <path
          d="M1200.45 234.079c1.837-2.593 1.222-6.185-1.371-8.02-2.593-1.837-6.185-1.223-8.02 1.37-1.837 2.594-1.223 6.185 1.37 8.021 1.852 1.31 4.3 1.412 6.252.259l4.242 4.216c.458.483 1.22.502 1.702.044s.502-1.22.044-1.702c-.014-.015-.028-.03-.044-.044l-4.175-4.144zm-4.7.393c-2.049 0-3.71-1.66-3.712-3.709 0-2.05 1.66-3.711 3.71-3.712 2.047 0 3.707 1.657 3.711 3.704.004 2.05-1.655 3.714-3.706 3.717h-.002z"
          transform="translate(-1190 -225)"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 40
    },
    variant: {
      type: String,
      default: 'dark' // light, neutral, dark
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#151515'
      }
      if (props.variant === 'neutral') {
        return '#c7c7c7'
      }
      if (props.variant === 'light') {
        return '#fafafa'
      }

      // Default: light
      return '#e5e5e5'
    })()
    
    return {
      color,
    }
  }
})
</script>
