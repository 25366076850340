<template>
  <div class="menu-body__items">
    <div
      v-for="(menuItem, menuItemIdx) in contentView"
      :key="menuItemIdx"
      class="menu-item"
    >
      <div
        class="label"
        @click="setSelectedMenuItem(menuItemIdx)"
      >
        <SplNuxtLink
          :override-lang="menuItem.lang"
          :to="menuItem.url"
          is-external
          :no-lang="noLang"
          :rel="relAlternate ? 'alternate' : ''"
          :is-target-blank="menuItem.targetBlank"
          :is-disabled="!menuItem.url"
        >
          <div class="inline">
            <template v-if="shouldDisplayImage(menuItem)">
              <span v-if="getImageTag(menuItem) === 'es'">
                <img
                  class="link-collection__item__img"
                  src="~/assets/images/fun-with-flags/es.png"
                >
              </span>
              <span v-else-if="getImageTag(menuItem) === 'pt'">
                <img
                  class="link-collection__item__img"
                  src="~/assets/images/fun-with-flags/pt.png"
                >
              </span>
            </template>

            <DynLang
              capitalize-first
              :by-key="menuItem.title"
              :default-label="menuItem.title"
            />
          </div>
        </SplNuxtLink>

        <button v-if="hasChildren(menuItem)">
          <ArrowIcon
            :direction="!shouldDisplay(menuItemIdx) ? 'right' : 'bottom'"
            :size="18"
          />
        </button>
      </div>
      <transition name="fade">
        <ul
          v-if="shouldDisplay(menuItemIdx)"
          class="submenu"
        >
          <li
            v-for="(menuSubItem, menuSubItemIdx) in menuItem.children"
            :key="menuSubItemIdx"
            class="submenu-item"
          >
            <SplNuxtLink
              :to="menuSubItem.url"
              :override-lang="menuSubItem.lang"
              is-external
              :no-lang="noLang"
              :rel="relAlternate ? 'alternate' : ''"
              :is-target-blank="menuSubItem.targetBlank"
            >
              <div class="inline">
                <template v-if="shouldDisplayImage(menuSubItem)">
                  <span v-if="getImageTag(menuSubItem) === 'es'">
                    <img
                      class="link-collection__item__img"
                      src="~/assets/images/fun-with-flags/es.png"
                    >
                  </span>
                  <span v-else-if="getImageTag(menuSubItem) === 'pt'">
                    <img
                      class="link-collection__item__img"
                      src="~/assets/images/fun-with-flags/pt.png"
                    >
                  </span>
                </template>

                <DynLang
                  capitalize-first
                  :by-key="menuSubItem.title"
                  :default-label="menuSubItem.title"
                />
              </div>
            </SplNuxtLink>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../SplNuxtLink.vue'

import ArrowIcon from '../icons/Arrow.icon.vue'

import { translateByKeySilent } from '~/core/ts/util/translate'
import { capitalizeAllWords } from '~/core/ts/util/string'

import { type MenuItem } from '~/core/ts/entity/MenuItem'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    ArrowIcon,
  },
  props: {
    content: {
      type: Array as PropType<Array<MenuItem>>,
      required: true,
    },
    noLang: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (prop: string) => ['sm', 'lg'].includes(prop),
      default: 'lg',
    },
    relAlternate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const runtimeConfig = useRuntimeConfig()
    const domain = runtimeConfig.public.domain

    const selectedIndex = ref(-1)

    function setSelectedMenuItem(index: number) {
      if (selectedIndex.value === index) {
        selectedIndex.value = -1
      } else {
        selectedIndex.value = index
      }
    }

    function hasChildren(item: any) {
      if (!item.children) {
        return false
      }

      return item.children.length > 0
    }

    function shouldDisplay(itemIndex: any): boolean {
      return selectedIndex.value === itemIndex
    }

    function shouldDisplayImage(item: MenuItem) {
      if (!item.metadata) {
        return false
      }

      if (!item.metadata.imgByKey) {
        return false
      }

      return true
    }

    function getImageTag(item: MenuItem) {
      if (!item.metadata) {
        return ''
      }

      if (!item.metadata.imgByKey) {
        return ''
      }

      return item.metadata.imgByKey
    }

    const contentView = computed(() => {
      return props.content.map((item) => {
        const title = translateByKeySilent(item.title)
        if (!title) {
          return item
        }

        let children: Array<any> = []
        if (item.children) {
          children = item.children.map((subitem) => {
            const title = translateByKeySilent(subitem.title)
            if (!title) {
              return subitem
            }

            return {
              ...subitem,
              title: capitalizeAllWords(title),
            }
          })
        }

        return {
          ...item,
          title: capitalizeAllWords(title),
          children,
        }
      })
    })

    return {
      setSelectedMenuItem,
      hasChildren,
      shouldDisplay,

      shouldDisplayImage,
      getImageTag,

      contentView,
    }
  },
})
</script>

<style lang="scss" scoped>
.submenu {
  @apply pl-20p pt-10p;
  @apply text-base;
  @apply text-gray-100;
}

.inline {
  @apply flex justify-start;
  @apply items-center;
  @apply gap-2;
  @apply flex-1;
}

img {
  @apply w-6;
  @apply max-w-6;

  @apply border-1;
  @apply border-spl-primary;
  @apply rounded-full;
}

.menu-item {
  @apply p-6;
  @apply text-xs;
  @apply text-xl;

  @apply text-gray-100;
  @apply font-light;
  @apply border-solid border-white border-opacity-20 border-t;
  &:last-child {
  }
  &--sm {
    @apply text-lg;
  }

  .label {
    @apply flex justify-between;
  }

  &.title {
    @apply my-auto;
  }

  &.submenu {
    @apply bg-white w-full uppercase pl-20p;
  }

  .fade-enter-active,
  .fade-leave-active {
    opacity: 1;
    transition: 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

.submenu-item {
  @apply py-20p;
  border-bottom: 1px solid #1d1d1d;

  &:last-child {
    @apply border-none;
  }

  .title {
    @apply transition-all duration-300  h-full w-full;
    @apply text-lg;

    &:hover {
      @apply font-bold;
    }
  }
}
</style>
